
























































































































































import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import PrestationExpertiseLayout from '@/views/layouts/prestation-expertise.layout.vue';
import PrestationExpertise from '@/views/prestation-expertise/prestation-expertise';
import PrestationExpertiseSections from '@/components/prestation-expertise/prestation-expertise-sections.vue';
import PrestationExpertiseDesktopMenu from '@/components/prestation-expertise/prestation-expertise-desktop-menu.vue';
import PrestationExpertiseMobileMenu from '@/components/prestation-expertise/prestation-expertise-mobile-menu.vue';
import PrestationExpertiseHeaderActions from '@/components/prestation-expertise/prestation-expertise-header-actions.vue';
import { rapportExpertiseValidation } from '@/validations/rapport-expertise.validation';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import {
  isContratConditionOccupationOccupe,
  isContratTypePrestationNotCertificatExpertise,
  isRapportVersionGreaterOrEqualsTo,
  isRapportVersionLowerOrEqualsTo,
  isContratTypeVisiteExterieur,
} from '@/helpers/rapport-expertise.helper';
import { PieceJointeRapportDto } from '@/shared/dtos/piece-jointe-rapport.dto';
import { BModal } from 'bootstrap-vue';
import { MissionComparableDto } from '@/shared/dtos/mission-comparable.dto';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import PanelComparables from '@/components/rapport-expertise/panel-comparables.vue';
import VueScrollTo from 'vue-scrollto';
import moment from 'moment';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { getRapportExpertiseNoteSectionEnumName } from '@/shared/utilities/enums.utility';
import { RapportExpertiseNoteSectionEnum } from '@/shared/enums/rapport-expertise-note-section.enum';
import { isTypeDeBienTerrain } from '@/helpers/prestation-expertise.helper';
import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { TypePieceJointeRapportEnum } from '@/shared/enums/type-piece-jointe-rapport.enum';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { GeographiqueCommoditeDto } from '@/shared/dtos/geographique-commodite.dto';
import { SubjectMessage } from '@/shared/models/subject-message';
import { MergeDto } from '@/shared/dtos/merge.dto';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import PrestationExpertiseMissionMerge from '@/shared/components/prestation-expertise/prestation-expertise-mission-merge.vue';
import { InterventionDto } from '@/shared/dtos/intervention.dto';
import { getContratTypeVisiteIdFromNatureVisiteEnum, getNatureVisiteFromContratTypeVisiteId } from '@/helpers/match.helper';
import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';

@Component({
  name: 'PrestationExpertiseRapport',
  components: {
    // Layout
    PrestationExpertiseLayout,
    PrestationExpertiseSections,
    PrestationExpertiseDesktopMenu,
    PrestationExpertiseMobileMenu,
    PrestationExpertiseHeaderActions,
    PrestationExpertiseMissionMerge,
    NumericNullable,
    PanelComparables,
  },
  validations: rapportExpertiseValidation,
})
export default class PrestationExpertiseRapport extends PrestationExpertise {
  public rapportExpertiseNoteSectionEnum = RapportExpertiseNoteSectionEnum;

  @Provide('addPieceJointe') addPieceJointeProvide: any = this.addPieceJointeRapport;
  @Provide('deletePieceJointeByIdAndType') deletePieceJointeByIdAndTypeProvide: any = this.deletePieceJointeRapportByIdAndType;
  @Provide('renamePieceJointe') renamePieceJointeProvide: any = this.renamePieceJointeRapport;
  @Provide('updateOrdrePiecesJointes') updateOrdrePiecesJointesRapportProvide: any = this.updateOrdrePiecesJointesRapport;
  @Provide('apposerVisa') apposerVisaProvide: any = this.apposerVisa;
  @Provide('refuserVisa') refuserVisaProvide: any = this.refuserVisa;

  public isPanelComparablesVisible: boolean = false;
  public isSearchingComparables: boolean = false;
  public comparables: MissionComparableDto[] = [];
  public donneesEstimationImmobiliere: DonneesEstimationImmobiliereDto = null;

  constructor() {
    // Cannot pass componentsSections in the parent bescause @Component doesn't support properties in contructor...
    super();
  }

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    if (this.hasAccess) {
      this.initComponentSections();

      await this.initDonneesEstimationImmobiliere();
      await this.initComparables();
      await this.initMergeRapportDatas();

      Vue.prototype.$subjectMessageService.subject.subscribe(async (subjectMessage: SubjectMessage) => {
        if (subjectMessage.type === SubjectMessageTypeEnum.INIT_DONNEES_ESTIMATION_IMMOBILIERES) {
          await this.initDonneesEstimationImmobiliere();
        }
      });
    }
  }

  public mounted(): void {
    // En v3.1 on n'affiche plus Non demandé
    if (isRapportVersionGreaterOrEqualsTo(this.rapport, 3.1)) {
      this.businessEntries.annexesStatuts = this.businessEntries.annexesStatuts.slice(1);
    }

    if (this.hasAccess) {
      const refModal = this.$refs.modalSuppression as unknown as BModal;
      refModal.$on('change', (is_visible: boolean) => {
        if (!is_visible) {
          refModal.$off('ok');
          refModal.$off('cancel');
        }
      });

      // Watch à cet endroit là pour éviter de le trigger dès le début et MAJ la date de modification sans le vouloir
      // VueJS semble mettre à jour l'objet mission
      setTimeout(() => {
        this.$watch(
          'rapport.proprietes.estEditionForcee',
          async () => {
            this._needToByPasseHasPrestationExpertiseChanged = true;
            const logMessage = `Sauvegarde des propriétés: ${this.mission.reference}`;
            this.saveInStorage(true);

            await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'RapportExpertise');

            Vue.prototype.$rapportExpertiseApiService
              .updateProprietes(this.rapport.id, this.rapport.proprietes)
              .then(async () => {
                await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'RapportExpertise');
                Vue.prototype.$notificationService.success(`Les propriétés ont été sauvegardées avec succès.`);
              })
              .catch(async (error: any) => {
                Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la sauvegarde des propriétés.`);
                await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'RapportExpertise');
              });
          },
          { deep: true }
        );
      }, 50);
    }
  }
  //#endregion

  //#region COMPUTED
  get rapport(): RapportExpertiseDto {
    return this.mission.prestationExpertise as RapportExpertiseDto;
  }

  set rapport(value: RapportExpertiseDto) {
    this.mission.prestationExpertise = value;
  }

  get isReadOnly(): boolean {
    if (
      this.rapport.proprietes.estEditionForcee &&
      (store.user.roles.includes(roles.AdminBPCEEI) || store.user.roles.includes(roles.Expert))
    ) {
      return false;
    }

    if (
      this.rapport.forceReadonly ||
      !this.$can('Save', 'Rapport') ||
      (this.mission.etape !== EtapeMissionEnum.AttribueeALExpert &&
        this.mission.etape !== EtapeMissionEnum.ValeursAViserVisa1 &&
        this.mission.etape !== EtapeMissionEnum.ValeursAViserVisa2 &&
        this.mission.etape !== EtapeMissionEnum.ValeursARevoir &&
        this.mission.etape !== EtapeMissionEnum.ExpertiseCompleteAViser) ||
      this.mission.expertise.suiviMission.estDepose
    ) {
      return true;
    }

    // On garde ce code au cas ou visatech
    // if (
    //   (this.mission.etape === EtapeMissionEnum.ValeursAViserVisa1 || this.mission.etape === EtapeMissionEnum.ValeursAViserVisa2) &&
    //   this.$can('SaveWhileVisa', 'Rapport')
    // ) {
    //   return this.mission.dateVisaTech != null;
    // }

    return false;
  }

  get isRapport(): boolean {
    return this.rapport != null ? isContratTypePrestationNotCertificatExpertise(this.rapport.contratTypePrestationId) : null;
  }

  get color(): string {
    return this.isRapport ? '#35a2eb' : '#00ab9d';
  }

  get isFraisDisabled(): boolean {
    return this.isReadOnly || (this.mission.expert && this.mission.expert.id !== store.user.omereContactId && !this.$can('Save', 'Frais'));
  }

  //#endregion

  //#region WATCH

  //#endregion

  @Watch('mission.prestationExpertise.contratCategorieDActifId')
  public contratCategorieDActifIdChanged(newValue: any, oldValue: number): void {
    if (oldValue !== undefined && oldValue !== newValue) {
      this.updateComponentSections();
    }
  }

  @Watch('mission.prestationExpertise.contratConditionOccupationId')
  public contratConditionOccupationIdChanged(newValue: number, oldValue: number): void {
    if (oldValue !== undefined && oldValue !== newValue) {
      this.updateComponentSections();
    }
  }

  //#endregion

  //#region EVENTS
  public onEmitComparable(comparable: any): void {
    if (this.isReadOnly) {
      Vue.prototype.$notificationService.warn(`Impossible d'ajouter un élément de comparaison.`, 'Le rapport en lecture seule');

      return;
    }

    this.rapport.etudeMarcheElementsComparaisonsVentes.push({
      id: 0,
      dateTransaction: moment(comparable.date, 'DD/MM/YYYY').format(),
      description: comparable.description.replace('<br/>', '\n'),
      prixOuLoyer: comparable.prixMoyen,
      rapportExpertiseId: +this.rapport.id,
      surface: comparable.surface,
      typeDeSourceId: 2016004, // Valorisation
      vente: true,
    });

    Vue.prototype.$notificationService.success(`L'élément de comparaison a été ajouté avec succès.`);

    VueScrollTo.scrollTo('.elementComparaison > li:last-child', 500, {
      offset: -75,
    });
  }

  public async onEmitReferenceToCopyChanged(fromReference: string): Promise<void> {
    this.isSaving = true;

    this.$bvToast.show('copy-toast');

    const logMessage = `Copie des données de la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');

    Vue.prototype.$missionApiService
      .updateExpertiseFromReference(this.mission, fromReference.toUpperCase())
      .then(async (mission: MissionDto) => {
        this.mission = mission;

        this.mission.prestationExpertise.dateDeSauvegardeUtc = this.mission.prestationExpertise.dateModificationUtc;

        this._hasMissionChanged = false;
        this._hasPrestationExpertiseChanged = false;

        await this.saveInStorage(true);
        this.$forceUpdate();

        document.querySelector('main').scrollTo({
          top: 0,
          left: 0,
        });

        Vue.prototype.$notificationService.success(`Les données du rapport de la mission ${fromReference} ont été copiées avec succès.`);

        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(
          `Une erreur est survenue lors de la copie des données de la prestation d'expertise attachée à la mission ${fromReference}.`
        );
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseRapport');
      })
      .finally(() => {
        this.isSaving = false;
        this.$bvToast.hide('copy-toast');
      });
  }

  //#endregion

  //#region FUNCTIONS
  protected cleanBeforeSaveInStorage(missionToClean: MissionDto): void {}

  protected async cleanBeforeSave(): Promise<void> {}

  protected touchDatas(): boolean {
    this.$v.mission.prestationExpertise.$touch();
    this.$v.mission.prestationExpertise.piecesJointesRapport.$touch();

    if (this.$v.mission.prestationExpertise.$invalid)
      Object.keys(this.$v.mission.prestationExpertise).forEach((key) => {
        let val: any = this.$v.mission.prestationExpertise[key as keyof FormData];

        if (val && val.$error == true) {
          /* eslint-disable no-console */
          console.log('Invalid value for: ', key);
        }
      });

    return this.$v.mission.prestationExpertise.$invalid || this.$v.mission.prestationExpertise.piecesJointesRapport.$invalid;
  }

  public async addPieceJointeRapport(
    type: string,
    base64Content: string,
    nom: string,
    index: number = null,
    isMain: boolean = false
  ): Promise<number> {
    const timestamp = new Date().getTime();

    const pieceJointe: PieceJointeRapportDto = {
      id: timestamp,
      type: type as unknown as TypePieceJointeRapportEnum,
      chemin: base64Content,
      nom: nom,
      ordre: index ?? (this.rapport.piecesJointesRapport[type] ? this.rapport.piecesJointesRapport[type].length + 1 : 1),
      rapportExpertiseId: +this.rapport.id,
      dateModificationUtc: new Date(),
      isNew: true,
      isMain: isMain,
    };

    if (type == TypePieceJointeRapportEnum[TypePieceJointeRapportEnum.PlanCadastre]) {
      this.rapport.piecesJointesRapport[type] = [];
    }

    if (
      type == TypePieceJointeRapportEnum[TypePieceJointeRapportEnum.ComparableVente] ||
      type == TypePieceJointeRapportEnum[TypePieceJointeRapportEnum.ComparableLocation]
    ) {
      if (this.rapport.piecesJointesRapport[type].length - 1 < index) {
        this.rapport.piecesJointesRapport[type].push(pieceJointe);
      } else {
        this.rapport.piecesJointesRapport[type][index] = pieceJointe;
      }
    } else {
      this.rapport.piecesJointesRapport[type].push(pieceJointe);
    }

    return pieceJointe.id;
  }

  public async deletePieceJointeRapportByIdAndType(id: number, type: string): Promise<void> {
    const pieceJointeToDelete = this.rapport.piecesJointesRapport[type].find((pieceJointe: PieceJointeRapportDto) => pieceJointe.id === id);

    if (pieceJointeToDelete.isNew) {
      this.rapport.piecesJointesRapport[type] = this.rapport.piecesJointesRapport[type].filter(
        (pieceJointe: PieceJointeRapportDto) => pieceJointe.id !== id
      );
    } else {
      pieceJointeToDelete.isDeleted = true;
    }

    // Re affect the table to trigger change
    this.rapport.piecesJointesRapport[type] = [...this.rapport.piecesJointesRapport[type]];
  }

  public async renamePieceJointeRapport(pieceJointeRapportId: number, type: string, nom: string): Promise<void> {
    const pieceJointeToRename = this.rapport.piecesJointesRapport[type].find(
      (pieceJointeRapport: PieceJointeRapportDto) => pieceJointeRapport.id === pieceJointeRapportId
    );
    const index = this.rapport.piecesJointesRapport[type].indexOf(pieceJointeToRename);

    pieceJointeToRename.nom = nom;
    pieceJointeToRename.dateModificationUtc = new Date();
    this.rapport.piecesJointesRapport[type][index] = pieceJointeToRename;

    await this.saveInStorage();
  }

  public async updateOrdrePiecesJointesRapport(piecesJointesRapport: PieceJointeRapportDto[], type: string): Promise<any> {
    const now = new Date();
    piecesJointesRapport.forEach((pieceJointe: PieceJointeRapportDto) => (pieceJointe.dateModificationUtc = now));

    this.rapport.piecesJointesRapport[type] = piecesJointesRapport;

    await this.saveInStorage();
  }

  public async apposerVisa(): Promise<void> {
    store.isLoading = true;

    await this.cleanBeforeSave();

    const logMessage = `Validation du visa sur la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');

    const isOldGenPrestationExpertise = [
      TypePrestationExpertiseEnum.CertificatExpertise,
      TypePrestationExpertiseEnum.RapportExpertise,
    ].includes(this.mission.typePrestationExpertise);

    // PrestationExpertise.piecesJointes
    if (this.mission.prestationExpertise) {
      await Vue.prototype.$synchronizationService.managePiecesJointes(this.mission, isOldGenPrestationExpertise, false);
    }

    Vue.prototype.$rapportExpertiseApiService
      .apposerVisa(this.rapport.id, this.rapport)
      .then(async (response: any) => {
        if (response.status === 200) {
          this.mission.etape = EtapeMissionEnum.ExpertiseVerrouillee;
          Vue.prototype.$notificationService.success(`Le rapport a été visé avec succès.`);
          await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
          this._needToBeSaved = false;
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la validation du visa.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseRapport');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public async refuserVisa(): Promise<void> {
    store.isLoading = true;

    await this.cleanBeforeSave();

    const logMessage = `Refus du visa sur la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');

    const isOldGenPrestationExpertise = [
      TypePrestationExpertiseEnum.CertificatExpertise,
      TypePrestationExpertiseEnum.RapportExpertise,
    ].includes(this.mission.typePrestationExpertise);

    // PrestationExpertise.piecesJointes
    if (this.mission.prestationExpertise) {
      await Vue.prototype.$synchronizationService.managePiecesJointes(this.mission, isOldGenPrestationExpertise, false);
    }

    Vue.prototype.$rapportExpertiseApiService
      .refuserVisa(this.rapport.id, this.rapport)
      .then(async (response: any) => {
        if (response.status === 200) {
          this.mission.etape = EtapeMissionEnum.ValeursARevoir;
          Vue.prototype.$notificationService.success(`Le visa du rapport a été refusé avec succès.`);
          await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
          this._needToBeSaved = false;
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors du refus du visa.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseRapport');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  private initComponentSections(): void {
    this.componentsSections = [
      {
        slug: 'rapport',
        name: '',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'rapport-proprietes',
            name: 'propriétés',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseRapportProprietes',
            properties: {
              reference: this.mission.reference,
              proprietes: this.rapport.proprietes,
              prestationExpertiseId: this.rapport.id,
              isReadOnly: false,
            },
            outputs: {
              onReferenceToCopyChanged: this.onEmitReferenceToCopyChanged,
            },
            permission: 'SavePropriete|Rapport',
          },
          {
            slug: 'rapport-indicateurs',
            name: 'indicateurs',
            isOnlyForDesktop: true,
            isForCustomCondition:
              this.isBrowser &&
              ((this.mission.doublons != null && this.mission.doublons.length > 0) ||
                this.mission.commande.nbAnnexes > 0 ||
                this.mission.dateRestitution != null),
            componentName: 'PrestationExpertiseRapportIndicateurs',
            properties: {
              doublons: this.mission.doublons,
              nbAnnexes: this.mission.commande.nbAnnexes,
              dateRestitution: this.mission.dateRestitution,
            },
          },
        ],
      },
      {
        slug: 'contrat',
        name: 'contrat',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'contrat-mission',
            name: 'mission',
            isOnlyForDesktop: false,
            isActive: true,
            componentName: 'PrestationExpertiseRapportContratMission',
          },
        ],
        validationName: 'contratGroup',
      },
      {
        slug: 'situation-geographique',
        name: 'situation géographique',
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'situation-geographique-geographie',
            name: 'géographie',
            componentName: 'PrestationExpertiseRapportSituationGeographiqueGeographie',
          },
          {
            slug: 'situation-geographique-avis-expert',
            name: 'avis expert',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportSituationGeographiqueAvisExpert',
          },
          {
            slug: 'situation-geographique-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportSituationGeographiqueControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'situationGeographiqueGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.SituationGeographique),
      },
      {
        slug: 'situation-juridique',
        name: 'situation juridique',
        isOnlyForDesktop: false,
        isForCustomCondition: this.isRapport,
        children: [
          {
            slug: 'situation-juridique-juridique',
            name: 'juridique',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseRapportSituationJuridiqueJuridique',
          },
          {
            slug: 'situation-juridique-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportSituationJuridiqueControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'situationJuridiqueGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.SituationJuridique),
      },
      {
        slug: 'description-generale',
        name: 'description générale',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'description-generale-terrain',
            name: 'terrain',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseRapportDescriptionGeneraleTerrain',
          },
          {
            slug: 'description-generale-avis-expert',
            name: 'avis expert',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportDescriptionGeneraleAvisExpert',
          },
          {
            slug: 'description-generale-controle',
            name: 'contrôle',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportDescriptionGeneraleControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'descriptionGeneraleGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.DescriptionGenerale),
      },
      {
        slug: 'description-particuliere',
        name: 'description particulière',
        isOnlyForDesktop: false,
        isForCustomCondition: !isTypeDeBienTerrain(this.rapport, this.businessEntries),
        children: [
          {
            slug: 'description-particuliere-batiment',
            name: 'bâtiment',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseRapportDescriptionParticuliereBatiment',
          },
          {
            slug: 'description-particuliere-prestations',
            name: 'prestations',
            isOnlyForDesktop: false,
            isForCustomCondition: !isContratTypeVisiteExterieur(this.rapport.contratTypeVisiteId),
            componentName: 'PrestationExpertiseRapportDescriptionParticulierePrestations',
          },
          {
            slug: 'description-particuliere-surface',
            name: 'surface',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseRapportDescriptionParticuliereSurface',
          },
          {
            slug: 'description-particuliere-avis-expert',
            name: 'avis expert',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportDescriptionParticuliereAvisExpert',
          },
          {
            slug: 'description-particuliere-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportDescriptionParticuliereControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'descriptionParticuliereGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.DescriptionParticuliere),
      },
      {
        slug: 'situation-fiscale',
        name: 'situation fiscale',
        isForCustomCondition: this.isRapport && isRapportVersionLowerOrEqualsTo(this.rapport, 2.0),
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'situation-fiscale-fiscale',
            name: 'fiscalité',
            componentName: 'PrestationExpertiseRapportSituationFiscaleFiscalite',
          },
          {
            slug: 'situation-fiscale-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportSituationFiscaleControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'situationFiscaleGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.SituationFiscale),
      },
      {
        slug: 'situation-locative',
        name: 'situation locative',
        isForCustomCondition:
          this.isRapport &&
          isRapportVersionGreaterOrEqualsTo(this.rapport, 3.0) &&
          isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId),
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'situation-locative-locative',
            name: 'locative',
            componentName: 'PrestationExpertiseRapportSituationLocativeLocative',
          },
          {
            slug: 'situation-locative-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportSituationLocativeControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'situationLocativeGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.SituationLocative),
      },
      {
        slug: 'situation-urbanistique',
        name: 'situation urbanistique',
        isForCustomCondition: this.isRapport,
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'situation-urbanistique-urbanisme',
            name: 'urbanisme',
            componentName: 'PrestationExpertiseRapportSituationUrbanistiqueUrbanisme',
          },
          {
            slug: 'situation-urbanistique-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportSituationUrbanistiqueControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'situationUrbanistiqueGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.SituationUrbanistique),
      },
      {
        slug: 'marche',
        name: 'marché',
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'marche-etude-marche',
            name: 'étude de marché',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportMarcheEtudeDeMarche',
          },
          {
            slug: 'marche-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportMarcheControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'etudeMarcheGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.EtudeDeMarche),
      },
      {
        slug: 'evaluation',
        name: 'évaluation',
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'evaluation-evaluation',
            name: 'évaluation',
            componentName: 'PrestationExpertiseRapportEvaluationEvaluation',
          },
          {
            slug: 'evaluation-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportEvaluationControle',
            permission: 'Save|Notes',
          },
        ],
        validationName: 'evaluationGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.Evaluation),
      },
      {
        slug: 'conclusion',
        name: 'conclusion',
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'conclusion-conclusion',
            name: 'conclusion',
            componentName: 'PrestationExpertiseRapportConclusionConclusion',
          },
          {
            slug: 'conclusion-controle',
            name: 'contrôle',
            componentName: 'PrestationExpertiseRapportConclusionControle',
            permission: 'Save|Notes',
          },
          {
            slug: 'conclusion-annexes',
            name: 'annexes',
            componentName: 'PrestationExpertiseRapportConclusionAnnexes',
          },
        ],
        validationName: 'conclusionGroup',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.Conclusion),
      },
      {
        slug: 'controle',
        name: 'contrôle',
        isOnlyForDesktop: true,
        children: [
          {
            slug: 'controle-appreciation-generale',
            name: 'appréciation générale',
            isOnlyForDesktop: true,
            componentName: 'PrestationExpertiseRapportControleAppreciationGenerale',
          },
        ],
        permission: 'Save|Notes',
        noteName: getRapportExpertiseNoteSectionEnumName(this.rapportExpertiseNoteSectionEnum.Global),
      },
    ];
  }

  public updateComponentSections(): void {
    this.initComponentSections();
    this.filterComponentSections();
  }

  public async initComparables(): Promise<void> {
    if (!navigator.onLine || this.isReadOnly || this.isMobile || !this.$can('RechercheComparable', 'Rapport')) return;

    const logMessage = `Recherche des missions comparables attachée à la mission: ${this.mission.noMission}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');

    this.isSearchingComparables = true;

    await Vue.prototype.$missionApiService
      .getComparables(this.mission.id)
      .then(async (comparables: MissionComparableDto[]) => {
        this.comparables = comparables;
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération des comparables.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseRapport');
      })
      .finally(() => {
        this.isSearchingComparables = false;
      });
  }

  public async initDonneesEstimationImmobiliere(): Promise<void> {
    if (this.isMobile) return;

    store.isLoading = true;

    const logMessage = `Récupération des données PriceHubble de la prestation d'expertise attachée à la mission ${this.mission.reference}.`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');

    return await Vue.prototype.$rapportExpertiseApiService
      .getDonneesEstimationImmobiliere(this.rapport.id)
      .then(async (donnees: DonneesEstimationImmobiliereDto) => {
        this.donneesEstimationImmobiliere = donnees;

        await Vue.prototype.$rapportExpertiseApiService
          .getCommodites(this.rapport.id)
          .then(async (commodites: GeographiqueCommoditeDto[]) => {
            this._needToByPasseHasPrestationExpertiseChanged = true;
            this.rapport.geographiqueCommodites = commodites;
          });

        if (!this.lastPrestationExpertiseVersionHistory) {
          // Si les données ne sont pas disponibles à ce stade, on force le chargement
          await Vue.prototype.$prestationExpertiseVersionHistoryApiService
            .getWithRoute(`status/${this.mission.prestationExpertise.id}`)
            .then(async (prestationExpertiseVersionHistory: PrestationExpertiseVersionHistoryDto) => {
              this.lastPrestationExpertiseVersionHistory = prestationExpertiseVersionHistory;

              await this.loadDonneesEstimationImmobiliere();
            });
        } else {
          await this.loadDonneesEstimationImmobiliere();
        }

        Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.REFRESH_DONNEES_ESTIMATION_IMMOBILIERES, donnees);
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération des données PriceHubble.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseRapport');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  private async loadDonneesEstimationImmobiliere(): Promise<void> {
    // Si le rapport n'est pas en lecture seule et si le dernier push venant du mobile, alors on met à jour PriceHubble
    if (!this.isReadOnly && this.lastPrestationExpertiseVersionHistory?.strLastVersion.indexOf('iPhone') !== -1) {
      const input: DonneesEstimationImmobiliereUpdateInputDto = new DonneesEstimationImmobiliereUpdateInputDto();
      input.buildingYear = this.rapport.caracteristiquesAnneeConstruction;
      input.landArea = this.rapport.terrainSuperficie;
      input.livingArea = this.rapport.particuliereSurfaceTotaleHabitable;

      await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
    }
  }

  public async initMergeRapportDatas(): Promise<void> {
    if (!navigator.onLine) return;

    this.mission.natureVisite = getNatureVisiteFromContratTypeVisiteId(this.rapport.contratTypeVisiteId);
    this.initMergeDatas();
  }

  public async onEmitMergeMission(merges: MergeDto[]): Promise<void> {
    if (merges.length === 1 && merges[0].fieldName === 'TypePrestationExpertise') {
      const mergeTypePrestationExpertise = merges[0];
      return await this.changeTypePrestationExpertise(mergeTypePrestationExpertise, 'PrestationExpertiseRapport');
    }

    if (this.mission.intervention === undefined || this.mission.intervention === null) {
      this.mission.intervention = new InterventionDto();
    }

    merges.forEach((merge: MergeDto) => {
      switch (merge.fieldName) {
        case 'HasFourchetteValeur':
          this.mission.hasFourchetteValeur = merge.newValue;
          break;
        case 'HasValeurApresTravaux':
          this.mission.hasValeurApresTravaux = merge.newValue;
          break;
        case 'HasValeurLocative':
          this.mission.hasValeurLocative = merge.newValue;
          break;
        case 'HasValeurVenteForcee':
          this.mission.hasValeurVenteForcee = merge.newValue;
          break;
        case 'HasValeurVenteRapide':
          this.mission.hasValeurVenteRapide = merge.newValue;
          break;
        case 'NatureVisite':
          this.mission.natureVisite = merge.newValue;
          this.rapport.contratTypeVisiteId = getContratTypeVisiteIdFromNatureVisiteEnum(this.mission.natureVisite);
          break;
        case 'VisiteContactNomPrenom':
          this.mission.intervention.visiteContactNomPrenom = merge.newValue;
          break;
        case 'VisiteContactEmail':
          this.mission.intervention.visiteContactEmail = merge.newValue;
          break;
        case 'VisiteContactTel':
          this.mission.intervention.visiteContactTel = merge.newValue;
          break;
        case 'MotifPrestationExpertise':
          this.mission.motifPrestationExpertise = merge.newValue;
          break;
      }
    });
    Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.REFRESH_VALEURS_DATA);
    this._hasMissionChanged = true;
    await this.saveInStorage();
  }

  //#endregion
}
